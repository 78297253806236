import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'


import Navigation from '../components/Navigation'
import StaticNavigation from '../components/StaticNavigation'
import Footer from '../components/Footer'

function FAQ() {
  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/intrebari-frecvente" });
  }, [])


  return (
    <>
      <SEO
        title="Psiholog Cool - întrebări frecvente"
        description="Răspunsuri la cele mai frecvente întrebări despre psihoterapie."
        type="webapp"
        name="Psiholog Cool"
      />
      
      <MobileView>
        <Navigation/>
      </MobileView>
      
      <BrowserView>
        <StaticNavigation/>
      </BrowserView>

      <motion.div
        key="page"
        className="page faq contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      >
        <div className="content">
          <h1 className="page-title">FAQ</h1>
          <p>Diverse întrebări și răspunsuri care vă pot oferi o mai bună înțelegere a procesului psihoterapeutic și a beneficiilor sale.</p>
          <br/><br/><br/>
          <h3>Ce este psihoterapia?</h3>
          <p>Psihoterapia este o formă de tratament și intervenție psihologică în care un terapeut calificat lucrează cu un client pentru a explora și a adresa diverselor problemele ale sănătății mentale, emoționale sau comportamentale. Scopul este de a obține o mai bună înțelegere de sine, de a face față dificultăților și de a obține o sănătate mentală cât mai bună.</p>
          <br/>
          <h3>Cine poate beneficia de psihoterapie?</h3>
          <p>Orice persoană care se confruntă cu dificultăți emoționale, probleme de sănătate mentală sau care dorește să se dezvolte personal poate beneficia de psihoterapie. Poate fi utilă pentru gestionarea anxietății, depresiei, stresului, traumelor, tulburărilor alimentare, relațiilor dificile și multe altele.</p>
          <br/>
          <h3>Cum funcționează psihoterapia?</h3>
          <p>Psihoterapia implică stabilirea unei relații de încredere între terapeut și client. Terapeutul va utiliza diferite tehnici și abordări terapeutice pentru a explora problemele și a oferi sprijinul necesar. În cadrul ședințelor, se vor discuta emoțiile, gândurile și comportamentele pentru a identifica și a adresa problemele subiacente.</p>
          <br/>
          <h3>Cât timp durează o terapie?</h3>
          <p>Durata terapiei poate varia în funcție de nevoile și obiectivele fiecărei persoane. Unele probleme pot fi abordate în câteva ședințe, în timp ce altele pot necesita un angajament mai lung. Terapeutul va lucra în colaborare cu clientul pentru a stabili un plan de tratament adecvat.</p>
          <br/>
          <h3>Este psihoterapia confidențială?</h3>
          <p>Da, psihoterapia se bazează pe confidențialitatea absolută între terapeut și client. Informațiile dezvăluite în cadrul terapiei sunt protejate de secret profesional și nu sunt divulgate fără acordul explicit al clientului, cu excepția cazurilor în care există un pericol iminent pentru viața clientului sau a altora.</p>
          <br/>
        </div>
      </motion.div>
      <Footer/>
    </>
  );
}

export default FAQ;
