import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'
import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'


import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

function Legal() {
  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/legal" });
  }, [])

  return (
    <>
      <Navigation/>
      <motion.div
        key="page"
        className="page legal contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      > 
        <div className="content left">
          <h1 className="page-title">Legal</h1>
   
                <p>Mai jos gasiți <strong>termenii și condițiile</strong> (1), <strong>politica de confidențialitate</strong> (2).</p>
                
                <h2>1. Termeni și condiții</h2>

                <h3>Introducere</h3>
                <p>Vă rugăm să citiți cu atenție acești termeni și condiții ("Termeni și condiții") înainte de a utiliza acest site web. Accesul și utilizarea acestui site web implică acceptarea Termenilor și condițiilor de utilizare prezentate mai jos, precum și a tuturor legilor și regulamentelor aplicabile.</p>
                
                <h3>Definiții</h3>
                <p>În cadrul acestor Termeni și condiții, termenii de mai jos vor avea următoarele semnificații:</p>
                <p>"Site web" se referă la site-ul web și serviciile oferite de acesta, disponibil la adresa URL: https://psiholog.cool. Design-ul și conținutul site-ului aparțin companiei IULIA SCARLATESCU și sunt protejate de legislația privind proprietatea intelectuală;</p>
                <p>"Utilizator" se referă la orice persoană fizică sau juridică care accesează sau utilizează site-ul web și efectuează plăți prin intermediul Stripe;</p>
                <p>"Stripe" se referă la sistemul de plată online oferit de Stripe, Inc.;</p>
                <p>"Date personale" se referă la orice informații care se referă la o persoană fizică identificată sau identificabilă;</p>
                <p>"Plată" se referă la orice tranzacție de plată efectuată prin intermediul sistemului de plată Stripe.</p>
                <p>"Conținut" se referă la toate informațiile, texte, imagini, software-uri, programe, coduri și alte materiale disponibile pe site-ul web.</p>
                
                <h3>Accesul și utilizarea site-ului web</h3>
                <p>Accesul și utilizarea site-ului web sunt permise numai în conformitate cu acești Termeni și condiții. Utilizarea site-ului web în alte scopuri decât cele permise este strict interzisă.</p>
                <p>Site-ul web poate fi accesat numai de persoanele care au vârsta de cel puțin 18 ani și care au capacitatea legală de a încheia contracte.</p>
                <p>Utilizatorul se angajează să utilizeze site-ul web numai în scopuri legale și să respecte toate legile și regulamentele aplicabile în timpul utilizării acestuia.</p>
                <p>Utilizatorul trebuie să respecte acești Termeni și condiții și toate legile și regulamentele aplicabile în timpul utilizării site-ului web și a serviciilor oferite prin intermediul acestuia. Utilizatorul trebuie să furnizeze informații corecte și complete în timpul procesului de plată și să nu efectueze plăți frauduloase sau ilegale prin intermediul Stripe.</p>
                
                <h3>Procesul de plată</h3>
                <p>Utilizatorii pot efectua plăți prin intermediul sistemului de plată Stripe, care acceptă o varietate de metode de plată, inclusiv carduri de credit și de debit. Utilizatorii trebuie să furnizeze informațiile de plată corecte și să confirme plata înainte de a finaliza tranzacția.</p>

                <h3>Taxe și comisioane</h3>
                <p>Utilizatorii sunt responsabili pentru plata oricăror taxe sau comisioane asociate cu tranzacțiile efectuate prin intermediul Stripe. Site-ul web poate percepe o taxă suplimentară pentru utilizarea serviciilor sale.</p>

                <h3>Anularea și rambursarea plăților</h3>
                <p>Utilizatorii pot anula o plată efectuată prin intermediul sistemului de plată Stripe numai înainte ca plata să fie procesată și finalizată. Site-ul web poate oferi opțiuni de rambursare a plăților, în funcție de politicile sale de rambursare. Utilizatorii trebuie să respecte aceste politici și să furnizeze informațiile necesare pentru a efectua rambursarea.</p>

                <h3>Drepturile de proprietate intelectuală</h3>
                <p>Conținutul site-ului web este protejat de drepturile de proprietate intelectuală și este deținut de noi sau de terțe părți licențiate. Utilizatorul nu are dreptul să copieze, reproducă, modifice, distribuie sau să transmită conținutul site-ului web fără acordul scris al proprietarului.</p>
                
                <h3>Limitarea răspunderii</h3>
                <p>Site-ul web și conținutul său sunt oferite "așa cum sunt" și nu garantăm că site-ul web va fi disponibil în mod continuu sau fără erori. Nu suntem responsabili pentru orice prejudiciu, pierdere sau daună, directă sau indirectă, cauzată de utilizarea sau imposibilitatea de a utiliza site-ul web.</p>
                <p>Site-ul web nu va fi responsabil pentru nicio pierdere sau daună cauzată de utilizarea serviciilor oferite prin intermediul Stripe. Utilizatorii își asumă riscul utilizării acestor servicii și sunt responsabili pentru toate tranzacțiile efectuate prin intermediul lor.</p>
                
                <h3>Modificarea Termenilor și condițiilor</h3>
                <p>Ne rezervăm dreptul de a modifica Termenii și condițiile în orice moment și fără notificare prealabilă. Utilizatorul este responsabil pentru verificarea periodică a Termenilor și condițiilor pentru a fi la curent cu orice modificări.</p>  
                
                <h3>Litigii</h3>
                <p>Termenii și condițiile sunt guvernate și interpretate în conformitate cu legile din România. Orice dispută între utilizator și noi va fi rezolvată prin mijloace amicale sau prin instanțele competente din România.</p>
                
                <h3>Confidențialitatea datelor</h3>
                <p>Utilizarea site-ului web implică colectarea și prelucrarea de date personale ale utilizatorului. Pentru mai multe informații despre prelucrarea datelor personale, vă rugăm să consultați Politica de confidențialitate disponibilă pe site-ul web.</p>
                <p>Datele personale ale utilizatorilor colectate în timpul procesului de plată realizat de serviciul Stripe, sunt protejate de măsuri de securitate adecvate, inclusiv criptarea datelor și autentificarea utilizatorului. Utilizatorii sunt responsabili pentru păstrarea în siguranță a informațiilor lor de plată și pentru a nu le dezvălui sau a le partaja cu terțe părți.</p>

                <h3>Legături către alte site-uri web</h3>
                <p>Site-ul web poate conține legături către alte site-uri web care nu sunt controlate de noi și asupra cărora nu avem nicio responsabilitate. Utilizatorul accesează și folosește aceste site-uri web la propriul risc.</p>
                
                <h3>Renunțarea la drepturi</h3>
                <p>Orice renunțare sau neexecutare a drepturilor sau prevederilor din acești Termeni și condiții nu va fi considerată o renunțare la aceste drepturi sau prevederi și nu va afecta valabilitatea acestora.</p>
                
                <h3>Dispoziții finale</h3>
                <p>Dacă oricare dintre prevederile acestor Termeni și condiții este considerată nevalidă sau neexecutabilă, aceasta nu va afecta valabilitatea celorlalte prevederi. Acești Termeni și condiții reprezintă întregul acord dintre utilizator și noi cu privire la utilizarea site-ului web și înlocuiesc toate acordurile și înțelegerile anterioare sau concomitente.</p>

                <hr/>
                <h2>2. Politica de confidențialitate</h2> 

                <h3>Introducere</h3>
                <p>Această Politică de confidențialitate descrie modul în care colectăm, folosim și protejăm datele personale ale utilizatorilor noștri, în conformitate cu Regulamentul General privind Protecția Datelor cu Caracter Personal (GDPR) și alte legi relevante.</p>
                <h3>Definiții</h3>
                <p>În cadrul acestei Politici de confidențialitate, termenii de mai jos vor avea următoarele semnificații:</p>
                <p>"Site-ul web" se referă la site-ul web și serviciile oferite de acesta, disponibil la adresa URL: https://psiholog.cool;</p>
                <p>"Utilizator" se referă la orice persoană fizică sau juridică care accesează sau utilizează site-ul web;</p>
                <p>"Date personale" se referă la orice informații care se referă la o persoană fizică identificată sau identificabilă;</p>
                <p>"Procesare" se referă la orice operațiune sau set de operațiuni efectuate cu date personale, cum ar fi colectarea, stocarea, utilizarea și transmiterea acestora.</p>
                <h3>Date personale colectate</h3>
                <p>Colectăm date personale de la utilizatori în diferite moduri, inclusiv prin intermediul formularelor de contact, abonării la newsletter sau creării de conturi de utilizator. Datele personale pe care le colectăm pot include numele, adresa de e-mail, numărul de telefon, adresa IP și alte informații similare.</p>
                <h3>Scopul prelucrării datelor personale</h3>
                <p>Prelucrăm datele personale ale utilizatorilor în scopul furnizării serviciilor noastre, inclusiv pentru a comunica cu utilizatorii, a le furniza informații despre produsele și serviciile noastre și pentru a îndeplini alte cerințe legale.</p>
                <h3>Stocarea datelor personale</h3>
                <p>Datele personale ale utilizatorilor sunt stocate într-un mediu securizat, accesibil numai de către persoanele autorizate. Stocăm datele personale pentru perioada necesară îndeplinirii scopului pentru care au fost colectate sau în conformitate cu cerințele legale aplicabile.</p>
                <h3>Divulgarea datelor personale</h3>
                <p>Nu divulgăm datele personale ale utilizatorilor către terțe părți, cu excepția cazurilor în care este necesar să îndeplinim obligațiile legale sau atunci când utilizatorul ne-a acordat permisiunea expresă pentru acest lucru.</p>
                <h3>Drepturile utilizatorilor</h3>
                <p>Utilizatorii au dreptul să solicite accesul, rectificarea, ștergerea sau restricționarea procesării datelor personale, precum și să se opună procesării acestora. Pentru a exercita aceste drepturi, utilizatorii pot contacta echipa noastră de suport.</p>
                <h3>Modificarea Politicii de confidențialitate</h3>
                <p>Ne rezervăm dreptul de a modifica această Politică de confidențialtate în orice moment, fără a fi necesară notificarea prealabilă a utilizatorilor. Orice modificări vor fi publicate pe site-ul web și vor intra în vigoare imediat după publicare.</p>
                <h3>Securitatea datelor personale</h3>
                <p>Luăm măsuri de securitate tehnice și organizatorice adecvate pentru a proteja datele personale ale utilizatorilor împotriva accesului neautorizat, utilizării sau divulgării neautorizate.</p>
                <h3>Legături către alte site-uri web</h3>
                <p>Site-ul web poate conține legături către alte site-uri web care nu sunt controlate de noi și asupra cărora nu avem nicio responsabilitate. Utilizatorul accesează și folosește aceste site-uri web la propriul risc.</p>
                <h3>Contact</h3>
                <p>Pentru orice întrebări sau preocupări legate de această Politică de confidențialitate sau de practicile noastre de protecție a datelor personale, utilizatorii pot contacta echipa noastră de suport la adresa de e-mail hey@psiholog.cool.</p>
                <p>Ultima actualizare: 9 Iulie 2023.</p>        </div>
      </motion.div>
      <Footer/>
    </>
  );
}

export default Legal;
