import React, { useState, useEffect, useNavigate } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion, useViewportScroll, useTransform } from 'framer-motion'
import { contentAnimation } from '../components/Animations'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'


import Navigation from '../components/Navigation'
import StaticNavigation from '../components/StaticNavigation'
import Footer from '../components/Footer'

import despreIMG from '../assets/img/despre.png'
import iProfileIMG from '../assets/img/profile.png'
import oProfileIMG from '../assets/img/profile-o.png'

import mentalIMG from '../assets/img/mental.svg'
import communicationIMG from '../assets/img/communication.svg'
import developmentIMG from '../assets/img/development.svg'
import stressIMG from '../assets/img/stress.svg'
import resilienceIMG from '../assets/img/resilience.svg'

function Despre() {
  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/despre" });
  }, [])


  const { scrollYProgress } = useViewportScroll();

  const leftAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.3], [0.5, 1]),
    translateX: useTransform(scrollYProgress, [0, 0.3], [1000, 0])
  };

  const rightAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.3], [0.5, 1]),
    translateX: useTransform(scrollYProgress, [0, 0.3], [-1000, 0])
  };

  const zoomAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.5], [1.5, 1]),
  };

  const profileAnimation = {
    scale: useTransform(scrollYProgress, [1, 0.3], [1.5, 0.5]),
  };

  const opacityAnimation = {
    opacity: useTransform(scrollYProgress, [0, 0.1], [0, 1]),
  };

  const verticalAnimation = {
    // scale: useTransform(scrollYProgress, [0, 0.3], [1.1, 1]),
    translateY: useTransform(scrollYProgress, [0, 1], [0, 500])
  };

  const [showModal, setShowModal] = useState(false)
  
  function showCalendlyFunction(){
    setShowModal(true)
  }
  const hideModal = (hideModal) => {
    console.log(hideModal);
    setShowModal(hideModal)
  }

  return (
   <>
    <SEO
        title="Psiholog Cool - Despre"
        description="Un mediu sigur și confidențial în care utilizatorii pot explora și aborda problemele emoționale, beneficiind de îndrumare specializată și intervenții validate științific."
        type="webapp"
        name="Psiholog Cool"
      />
      
    <MobileView>
      <Navigation showModal={showModal} hideModal={hideModal}/>
    </MobileView>

    <BrowserView>
      <StaticNavigation showModal={showModal} hideModal={hideModal}/>
    </BrowserView>

    <motion.div
      key="page"
      className="page about contentAnimation"
      variants={ contentAnimation } 
      animate='show' 
      exit='hide'
    >
      <div className="content wide">
        <div className="grid">
          <div className="col top">
            <div><p>Despre</p>
            <h1 className="page-title">Proiectul Psiholog Cool</h1></div>
          </div>
          <div className="col top">
            <div>
              <p>Psiholog Cool dorește să reducă obstacolele care împiedică oamenii să acceseze servicii de sănătate mentală. Suntem aici pentru a oferi o soluție rapidă pentru cei care caută ajutor, dar se simt blocați.</p>
              <p>Ne propunem să creăm un mediu sigur și confidențial în care utilizatorii pot explora și aborda problemele emoționale, beneficiind de îndrumare specializată și intervenții validate științific.</p>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
      </div>

      <div className="graphics">
        <motion.img src={despreIMG} className="despre" style={verticalAnimation}/>
      </div> 
      <br/>
      <div className="content wide">
        <h2>Cum ajutăm?</h2>
        <p>Ne concentrăm pe următoarele aspecte principale:</p>
        <br/>
        <br/>
        <br/>
      </div>

{/*      <div className="grid">
        <div className="col top">
          <div>
            <motion.img src={mentalIMG} className="small" style={zoomAnimation}/>
            <motion.div style={opacityAnimation}>
              <h3>Îmbunătățirea sănătății mintale</h3>
              <p>Sănătatea mintală este la fel de importantă ca și sănătatea fizică. Psihologul poate ajuta la gestionarea și tratarea unor probleme cum ar fi anxietatea, depresia, stresul și tulburările de somn. Terapia oferă instrumente și strategii pentru a face față cu succes dificultăților și pentru a îmbunătăți sănătatea mintală generală.</p>
            </motion.div>
          </div>
        </div>

        <div className="col top">
          <div>
            <motion.img src={communicationIMG} className="small" style={zoomAnimation}/>
            <motion.div style={opacityAnimation}>
              <h3>Îmbunătățirea abilităților de comunicare</h3>
              <p>Psihologii sunt experți în relații interpersonale și comunicare. Discuțiile regulate cu un psiholog vă poată ajuta să învățați să comunicați mai eficient, să vă exprimați asertiv nevoile și să rezolvați conflictele într-un mod sănătos. Aceste abilități sunt utile în relațiile personale și profesionale și în viața de zi cu zi.</p>
            </motion.div>
          </div>
        </div>

        <div className="col top">
          <div>
            <motion.img src={developmentIMG} className="small" style={zoomAnimation}/>
            <motion.div style={opacityAnimation}>
              <h3>Conștientizarea și dezvoltarea personală</h3>
              <p>Psihologii vă ajută să vă înțelegeți mai bine sentimentele, gândurile și comportamentele. Explorându-vă pe dumneavoastră și experiențele dumneavoastră, puteți descoperi noi aspecte ale dumneavoastră și puteți obține o perspectivă mai clară asupra valorilor, pasiunilor și obiectivelor voastre. Această autodescoperire vă poate ajuta să luați decizii mai informate și să vă dezvoltați personal.</p>
            </motion.div>
          </div>
        </div>

        <div className="col top">
          <div>
            <motion.img src={stressIMG} className="small" style={zoomAnimation}/>
            <motion.div style={opacityAnimation}>
              <h3>Gestionarea stresului și a traumelor</h3>
              <p>Viața este plină de factori de stres și de evenimente traumatice. Psihologii vă pot ajuta să înțelegeți și să gestionați mai bine stresul,să vă eliberați emoțiile și să faceți față traumelor din trecut. Terapia poate fi un loc sigur pentru a explora și a vă vindeca de experiențele dificile.</p>
            </motion.div>
          </div>
        </div>
  
        <div className="col top">
          <div>
            <motion.img src={resilienceIMG} className="small" style={zoomAnimation}/>
            <motion.div style={opacityAnimation}>
              <h3>Construirea rezilienței</h3>
              <p>Unul dintre obiectivele terapiei este de a construi reziliența emoțională și de a vă dezvolta capacitatea de a face față adversităților vieții. Psihologii vă pot îndruma în dezvoltarea de strategii și tehnici pentru a crește reziliența și a vă consolida sănătatea mintală.</p>
            </motion.div>
          </div>
        </div>
      </div>*/}


      <div className="grid">
        <div className="col top boxed">
          <div className="">
            <img src={mentalIMG} className="small"/>
            <motion.div style={opacityAnimation}>
              <h3>Îmbunătățirea sănătății mintale</h3>
              <p>Sănătatea mintală este la fel de importantă ca și sănătatea fizică. Psihologul poate ajuta la gestionarea și tratarea unor probleme cum ar fi anxietatea, depresia, stresul și tulburările de somn. Terapia oferă instrumente și strategii pentru a face față cu succes dificultăților și pentru a îmbunătăți sănătatea mintală generală.</p>
            </motion.div>
          </div>
        </div>

        <div className="col top boxed">
          <div className="">
            <img src={communicationIMG} className="small"/>
            <motion.div style={opacityAnimation}>
              <h3>Îmbunătățirea abilităților de comunicare</h3>
              <p>Psihologii sunt experți în relații interpersonale și comunicare. Discuțiile regulate cu un psiholog vă poată ajuta să învățați să comunicați mai eficient, să vă exprimați asertiv nevoile și să rezolvați conflictele într-un mod sănătos. Aceste abilități sunt utile în relațiile personale și profesionale și în viața de zi cu zi.</p>
            </motion.div>
          </div>
        </div>

        <div className="col top boxed">
          <div className="">
            <img src={developmentIMG} className="small"/>
            <motion.div style={opacityAnimation}>
              <h3>Conștientizarea și dezvoltarea personală</h3>
              <p>Psihologii vă ajută să vă înțelegeți mai bine sentimentele, gândurile și comportamentele. Explorându-vă pe dumneavoastră și experiențele dumneavoastră, puteți descoperi noi aspecte ale dumneavoastră și puteți obține o perspectivă mai clară asupra valorilor, pasiunilor și obiectivelor voastre. Această autodescoperire vă poate ajuta să luați decizii mai informate și să vă dezvoltați personal.</p>
            </motion.div>
          </div>
        </div>

        <div className="col top boxed">
          <div className="">
            <img src={stressIMG} className="small"/>
            <motion.div style={opacityAnimation}>
              <h3>Gestionarea stresului și a traumelor</h3>
              <p>Viața este plină de factori de stres și de evenimente traumatice. Psihologii vă pot ajuta să înțelegeți și să gestionați mai bine stresul,să vă eliberați emoțiile și să faceți față traumelor din trecut. Terapia poate fi un loc sigur pentru a explora și a vă vindeca de experiențele dificile.</p>
            </motion.div>
          </div>
        </div>
  
        <div className="col top boxed">
          <div className="">
            <img src={resilienceIMG} className="small"/>
            <motion.div style={opacityAnimation}>
              <h3>Construirea rezilienței</h3>
              <p>Unul dintre obiectivele terapiei este de a construi reziliența emoțională și de a vă dezvolta capacitatea de a face față adversităților vieții. Psihologii vă pot îndruma în dezvoltarea de strategii și tehnici pentru a crește reziliența și a vă consolida sănătatea mintală.</p>
            </motion.div>
          </div>
        </div>

      </div>


  

    <div className="content wide">
      <br/>
      <br/>
      <h2>Cine este în spatele proiectului?</h2>
      <br/>
      <br/>
      
      <div className="grid fold">
        

        <div className="col">
          <div>
            <h3>Iulia Scărlătescu</h3>
            <p>Iulia este psiholog și psihoterapeut atestat, având formare integrativă și cognitiv comportamentală. Ea a fondat acest proiect din dorința de a oferi un ajutor rapid celor care nu știu cui sa se adreseze sau cum să înceapă să se ocupe de problema care îi frământă. Misiunea ei este să te ajute să te înțeleagi mai bine, să faci față mai eficient problemelor tale, să reușești să îți atingi obiectivele personale și să ajungi să duci o viață cât mai împlinită și satisfăcătoare.</p>
          </div>
        </div>
        <div className="col">
          <motion.img src={iProfileIMG} className="profile" style={profileAnimation}/>
        </div>
      </div>
  
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="grid"> 
        <div className="col">
          <img src={oProfileIMG} className="profile"/>
        </div> 
        <div className="col">
          <div>
            <h3>Sergiu-Octavian Fediuc</h3>
            <p>Octavian, designer de produs și fondator al mai multor produse digitale, îmbină pasiunea sa pentru design și tehnologie cu interesul pentru psihologie și cunoaștere de sine. El este conștient de impactul profund pe care mintea noastră îl are asupra stării noastre generale și crede în puterea designului de a genera schimbări pozitive prin experiențe digitale bine definite.</p>
          </div>
        </div>
        
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>

      <div className="content wide">
        <h2>Tu ce faci când nu te simți bine cu tine? Dar când ai probleme acasă sau la serviciu?</h2>
        <p><button className="button ultra-big-btn" onClick={showCalendlyFunction}>Discut cu un psiholog!</button></p> 
        <br/>
      </div>
    </motion.div>
    <Footer/>
    </>
  );
}

export default Despre;
