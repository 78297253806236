import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion, useViewportScroll, useTransform } from 'framer-motion'
import { contentAnimation, chatAnimation, chatBarAnimation, navbarAnimation, circleAnimation } from '../components/Animations'

import { useInView } from "react-intersection-observer";

import { PopupButton } from "react-calendly";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'

import Navigation from '../components/Navigation'
import StaticNavigation from '../components/StaticNavigation'

import Chat from '../components/Chat-Interface'
import Footer from '../components/Footer'

import symbolIMG from '../assets/img/symbol.svg'
import testIMG from '../assets/img/testFace.jpg'
import logo from '../assets/img/logo.svg'

import cabinetIMG from '../assets/img/cabinet.jpg'

import gosLOGO from '../assets/img/logo-gos.png'
import lexonnLOGO from '../assets/img/logo-lexonn.png'
import vonfLOGO from '../assets/img/logo-vonf.png'
import colegiuLOGO from '../assets/img/logo-colegiu.png'

import mp4_1 from '../assets/video/5.mp4'
import mp4_2 from '../assets/video/9.mp4'
import mp4_3 from '../assets/video/6.mp4'
import mp4_4 from '../assets/video/8.mp4'


const boxVariant = {
  visible: { opacity: 1, x: [100, 0], transition: { duration: 0.5 } },
  hidden: { opacity: 1, x: [0, 100] }
};

const boxVariant2 = {
  visible: { opacity: 1, x: [-100, 0], transition: { duration: 0.5 } },
  hidden: { opacity: 1, x: [0, -100] }
};

function Landing() {

  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, [])

  const eventTrack = (category, action, label) => {
  // console.log("GA event:", category, ":", action, ":", label);
  ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }


  // GET URL
  const url = window.location;
  // console.log('url:', url);

  // GET PATHNAME
  const path = window.location.pathname;
  // console.log('path:', path);

  const chatLink = url + "messenger"
  const [showChat, setShowChat] = useState(true)
  const [chatBar, setChatBar] = useState(false)

  function hideChatFunction(){
    setShowChat(false);
    setChatBar(true);
  }

  function showChatFunction(){
    setShowChat(true);
    setTimeout(() => { 
      setChatBar(false);
    }, 1500);
  }



  const { scrollYProgress } = useViewportScroll();

  const leftAnimation = {
    // scale: useTransform(scrollYProgress, [0, 0.3], [0, 1]),
    translateX: useTransform(scrollYProgress, [0, 0.3], [1000, 0])
  };

  const rightAnimation = {
    // scale: useTransform(scrollYProgress, [0, 0.3], [0, 1]),
    translateX: useTransform(scrollYProgress, [0, 0.3], [-1000, 0])
  };

  const rightImageAnimation = {
    translateY: useTransform(scrollYProgress, [0.8, 0], [-100, 1000])
  };


  const logoListAnimation = {
    translateX: useTransform(scrollYProgress, [1, 0], [-200, 1000])
  };

  const verticalAnimation = {
    scale: useTransform(scrollYProgress, [1, 0], [1, 2]),
    translateY: useTransform(scrollYProgress, [1, 1], [-200, 1])
  };

  const zoomAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.3], [1, 3]),
  };

  const [showModal, setShowModal] = useState(false)
  
  function showCalendlyFunction(){
    setShowModal(true)
  }
  const hideModal = (hideModal) => {
    console.log(hideModal);
    setShowModal(hideModal)
  }

  return (
    <>
      <SEO
        title="Psiholog Cool - E cool la psiholog!"
        description="Dorim să reducem barierele care împiedică oamenii să acceseze servicii de sănătate mentală - o soluție când ai nevoie de ajutor rapid, dar nu știi de unde să începi."
        type="webapp"
        name="Psiholog Cool"
      />

      <MobileView>
        <Chat/>
      </MobileView>

      <BrowserView>

        <StaticNavigation showModal={showModal} hideModal={hideModal}/>

        { showChat ?
          <motion.div 
            className="chat-box chatAnimation"
            key="chat"
            variants={ chatAnimation } 
            animate='show' 
            exit='hide'
          >
            <button className="button show-hide-chat-btn hide" onClick={hideChatFunction}/>
            <iframe src={chatLink} height="100%" width="400" title="Chat"></iframe>   
          </motion.div>
        :null }

        {chatBar ?
          <motion.div 
            className="chat-bar chatBarAnimation"
            key="chat-bar"
            variants={ chatBarAnimation } 
            animate='show' 
            exit='hide'
          >
            <button className="button show-chat-btn" onClick={showChatFunction}>
              <img src={logo} className="chat-logo"/>
              <button className="button show-hide-chat-btn show"/>
            </button>
          </motion.div>
        :null}

        <motion.div
          key="page"
          className="landing contentAnimation"
          variants={ contentAnimation } 
          animate='show' 
          exit='hide'
        >
        
          <section>
            <div className="graphics-circles">

              <motion.div 
                className="circle-mask big circleAnimation"
                key="circle0"
                variants={ circleAnimation } 
                animate='show' 
                exit='hide'                
              >
                <motion.video className="landing-video" style={zoomAnimation} src={mp4_1} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
              </motion.div>

              {/*<motion.div 
                className="circle-mask small circleAnimation"
                key="circle1"
                variants={ circleAnimation } 
                animate='show' 
                exit='hide'
              >
                <motion.video className="landing-video" style={zoomAnimation} src={mp4_2} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
              </motion.div>*/}

              
           {/*   <motion.div 
                className="circle-mask medium circleAnimation"
                key="circle2"
                variants={ circleAnimation } 
                animate='show' 
                exit='hide'
              >
                <video className="landing-video" src={mp4_3} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
              </motion.div>*/}
              

              <motion.div 
                className="circle-mask semi-small circleAnimation"
                key="circle3"
                variants={ circleAnimation } 
                animate='show' 
                exit='hide'
              >
                <motion.video className="landing-video" style={zoomAnimation} src={mp4_4} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
              </motion.div>
            </div>

            <div className="content left">
              <br/><br/><br/>
              <img src={symbolIMG} className="main-logo"/>
              <h1 className="landing-page-title">E cool<br/> la psiholog!</h1>
              <p>Când simți că nu mai faci față în viața ta și ai nevoie de un ajutor sau când ai o întrebare despre cei dragi, dar nu știi de unde să începi, trimite-ne mesajul tău și alege data și ora când putem discuta. Ne propunem să reducem barierele care împiedică oamenii să acceseze servicii de sănătate mintală.</p>
              <motion.div 
                className="circle-mask small circleAnimation"
                key="circle1"
                variants={ circleAnimation } 
                animate='show' 
                exit='hide'
              >
                <motion.video className="landing-video" style={zoomAnimation} src={mp4_2} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
              </motion.div>
            </div>
          </section>
          
          <section className="gradient">
            <div className="content left">
              <h2 className="">Care sunt subiectele pe care le putem aborda?</h2>
              <p>Oferim un mediu sigur și confidențial în care vă puteți explora și aborda problemele, având însoțire de specialitate cu intervenții validate științific. Câteva subiecte pe care le putem aborda:</p>
              <br/><br/><br/>

              <motion.div 
                style={leftAnimation}
                className="service left"
              >
                <p><strong>Îmbunătățirea sănătății mintale și restabilirea echilibrului psihologic</strong><br/>Cum gestionăm anxietatea, depresia, stresul, burnoutul, atacurile de panica, insomnia sau tulburările de somn.</p>
              </motion.div>

              <motion.div 
                style={rightAnimation}
                className="service right"
              >
                <p><strong>Ameliorarea relațiilor cu persoanele importante din viața ta</strong><br/>Învață să comunici eficient în cuplu, în familie, la serviciu, să stabilești limite sănătoase și să depășești situațiile dificile.</p>
              </motion.div>

              <motion.div 
                style={leftAnimation}
                className="service left"
              >
                <p><strong>Dezvoltare personală</strong><br/>Descoperă-te și ia decizii mai informate.</p>
              </motion.div>

              <motion.div 
                style={rightAnimation}
                className="service right"
              >
                <p><strong>Gestionarea stresului și a traumelor</strong><br/>Eliberează-te de traume și gestionează stresul.</p>
              </motion.div>
              
              <motion.div 
                style={leftAnimation}
                className="service left"
              >
                <p><strong>Construirea rezilienței</strong><br/>Dezvoltă-te și fii pregătit pentru provocări.</p>
              </motion.div>

              <p><Link to="despre"><button className="button small-btn">Citește mai multe</button></Link></p>
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
          </section>

          <section>
            <div className="content wide">
              <div className="grid">
                <div className="col">
                  <div>
                    <h2>Ședințe online sau la cabinet. Tu alegi!</h2>
                    {/*<h3>Nu trebuie să faci față singur problemelor!</h3>*/}
                    <p>Primul pas este mai greu, așa că îți oferim o consultație introductivă gratuit (online, 15 minute) ca să te încurajăm.</p> 
                    <p>Alege să rămâi cool, consultă un psiholog acum!</p>
                    <br/>
                    <p>
                      <button className="button ultra-big-btn" onClick={showCalendlyFunction}>Vreau să mă programez!</button>
                    </p>
                  </div>
                </div>
                <div className="col">
                  <motion.div className="graphics" style={rightImageAnimation}>
                    <motion.img src={cabinetIMG} className="right" style={verticalAnimation}/>
                  </motion.div>
                </div>
              </div>
              <br/>
              <br/>
              <p><strong>Ne recomandă</strong></p>
              
              <div className="logo-list">
                <motion.div style={logoListAnimation}>
                  <Link className="brands" to="https://copsi.ro" target="_blank"><img src={colegiuLOGO} className="logo"/></Link> 
                  <Link className="brands" to="https://www.linkedin.com/pulse/florin-teleanu-c%2525C3%2525A2%2525C8%252599tig%2525C4%252583torul-games-science%3FtrackingId=1cmtxzsUo6Ga3qTnGf4QbA%253D%253D/?trackingId=1cmtxzsUo6Ga3qTnGf4QbA%3D%3D" target="_blank"><img src={gosLOGO} className="logo"/></Link>
                  <Link className="brands" to="https://lexonn.ro" target="_blank"><img src={lexonnLOGO} className="logo"/></Link>
                  <Link className="brands" to="https://vonfediuc.com" target="_blank"><img src={vonfLOGO} className="logo"/></Link>
                </motion.div>
              </div>
            </div>
          </section>

          <Footer/>

        </motion.div> 
      </BrowserView>
    </>
  );
}

export default Landing;
