import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'
import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'


import Footer from '../components/Footer'
import symbolIMG from '../assets/img/symbol.svg'

function PageNotFound() {
  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/404" });
  }, [])

  const location = useLocation();
  return (
    <>
      
      <motion.div
        key="page"
        className="page pagenotfound contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      > 
        <div className="content">
          <img src={symbolIMG} className="main-logo"/>
          <h1 className="page-title">404</h1>
          <p>Ups! Se pare că ne-am cam zăpăcit puțin. Ei, nu-i nimic!</p>
      
          <br/>
          
          <p><Link to="/"><button className="button big-btn">Mergi la pagina principală</button></Link></p> 
        </div>
      </motion.div>
      <Footer/>
    </>
  );
}

export default PageNotFound;
