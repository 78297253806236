import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'

import Newsletter from './Newsletter'
import symbol from '../assets/img/symbol.svg'
import vonFediuc from '../assets/img/logo-fediuc.svg'

function Footer() {
  return (
    <motion.footer
      key="footer"
      className="contentAnimation"
      variants={ contentAnimation } 
      animate='show' 
      exit='hide'
    >
      <div className="main-footer">
        <img src={symbol} className="logo mobile"/>
        {/*<p>Este cool la psiholog!</p>*/}
        <br/>
        <div className="grid">

          <div className="col top">
            <div>
              {/*<a href="mailto:hey@psiholog.cool"><button className="button small-btn">hey@psiholog.cool</button></a>*/}
              <br/>
              <br/>
              <br/>
              <br/>
              <p><strong>Informații utile în caz de urgență</strong></p>
              <h4>Dacă vă aflați într-o stare de pericol dumneavoastră sau pentru alte persoane, vă rugăm să contactați imediat <strong>TelVerde Antisuicid: 0800 801 200</strong> sau <strong>numărul național de urgență 112</strong>. Psiholog Cool nu este responsabil pentru starea dumneavoastră medicală.</h4>
            </div>
          </div>

          <div className="col top">
            <div>  
              {/*<Newsletter/>*/}
            </div>
          </div>

        </div>

          <br/>
          <br/>
          <br/>
          
        <div className="sub-footer">
          <Link to="https://vonfediuc.com" target="_blank" className="credit">
            <span className="credit-logo"/>
          </Link>
          <Link to="/legal" className="legal">LEGAL</Link>
        </div>
      </div>
    </motion.footer>
  );
}

export default Footer;
