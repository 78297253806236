import React, { useState, useEffect, useNavigate } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion, useViewportScroll, useTransform } from 'framer-motion'
import { contentAnimation, circleAnimation } from '../components/Animations'
import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'

import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

import symbol from '../assets/img/symbol.svg'
import gosLOGO from '../assets/img/logo-gos.png'
import lexonnLOGO from '../assets/img/logo-lexonn.png'
import vonfLOGO from '../assets/img/logo-vonf.png'
import colegiuLOGO from '../assets/img/logo-colegiu.png'

import mp4_1 from '../assets/video/5.mp4'
import mp4_2 from '../assets/video/9.mp4'
import mp4_3 from '../assets/video/6.mp4'
import mp4_4 from '../assets/video/8.mp4'

import faqIMG from '../assets/img/faq.svg'

function Help() {

  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/help" });
  }, [])

  const { scrollYProgress } = useViewportScroll();

  const leftAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.4], [0.5, 1]),
    translateX: useTransform(scrollYProgress, [0, 0.4], [1000, 0])
  };

  const rightAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.5], [0.5, 1]),
    translateX: useTransform(scrollYProgress, [0, 0.5], [-1000, 0])
  };

  const logoListAnimation = {
    translateX: useTransform(scrollYProgress, [1, 0.8], [-1000, 0])
  };

  const zoomAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.3], [1, 2.5]),
    translateY: useTransform(scrollYProgress, [0, 0.3], [0, 200])
  };

  const [showModal, setShowModal] = useState(false)
  
  function showCalendlyFunction(){
    setShowModal(true)
  }
  const hideModal = (hideModal) => {
    console.log(hideModal);
    setShowModal(hideModal)
  }

  return (
   <>
      <SEO
        title="Psiholog Cool - E cool la psiholog!"
        description="Dorim să reducem barierele care împiedică oamenii să acceseze servicii de sănătate mentală - o soluție când ai nevoie de ajutor rapid, dar nu știi de unde să începi."
        type="webapp"
        name="Psiholog Cool"
      />
      
      <Navigation showModal={showModal} hideModal={hideModal}/>
    
      <motion.div
        key="page"
        className="page help contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      > 
        <div className="graphics-h">
          <motion.div 
            className="circle-mask small-mobile circleAnimation"
            key="circle1"
            variants={ circleAnimation } 
            animate='show' 
            exit='hide'
          >
            <motion.video className="landing-video" style={zoomAnimation} src={mp4_1} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
          </motion.div>

          <motion.div 
            className="circle-mask medium-mobile circleAnimation"
            key="circle2"
            variants={ circleAnimation } 
            animate='show' 
            exit='hide'
          >
            <motion.video className="landing-video" style={zoomAnimation} src={mp4_4} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
          </motion.div>
        </div>
        
        <div className="content left">
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          <img src={symbol} className="logo mobile"/>
          <br/><br/>
          <h1 className="page-title">Ce este<br/>Psiholog Cool?</h1>
          <p>Dorim să reducem barierele care împiedică oamenii să acceseze servicii de sănătate mentală - o soluție când ai nevoie de ajutor rapid, dar nu știi de unde să începi.</p>
          <br/>
          <h2>Cum putem să ajutăm?</h2>
          <p>Oferim un mediu sigur și confidențial în care vă puteți explora și aborda problemele, având însoțire de specialitate cu intervenții validate științific.</p>
          <br/>
          <p>
            <Link to="/despre"><button className="button big-btn">Citește mai multe</button></Link>
          </p>
          <br/>
          <br/>

          <motion.div style={leftAnimation}>
            <Link to="/intrebari-frecvente">
              <div className="faq-container">
                <div className="content wide">
                  <img src={faqIMG} className="faq"/>
                  <h3><center>Întrebări frecvente</center></h3>
                  <br/>
                </div>
              </div>
            </Link>
          </motion.div>
  
          <motion.div style={rightAnimation}>
            <Link to="/onorariu">
              <div className="color">
                <div className="content wide">
                  <h3><center>Onorariu</center></h3>
                  <br/>
                </div>
              </div>
            </Link>
          </motion.div>
          <br/>
          <h2>Ședințe online sau <br/>la cabinetul nostru. Tu alegi!</h2>
          <h3>Nu trebuie să faci față singur problemelor!</h3>
          <br/>
          <p>Primul pas este mai greu. Îți oferim o consultație introductivă gratuit (online, 15 minute) ca să te încurajăm. Alege să rămâi cool, consultă un psiholog acum!</p>
          <br/>
          <button className="button big-btn" onClick={showCalendlyFunction}>Vreau sa ma programez!</button>
          <br/>
          <p><strong>Ne recomandă</strong></p> 
          <br/>
          <div className="logo-list">
            <motion.div style={logoListAnimation}>
              <Link className="brands" to="https://copsi.ro" target="_blank"><img src={colegiuLOGO} className="logo"/></Link> 
              <Link className="brands" to="https://www.linkedin.com/pulse/florin-teleanu-c%2525C3%2525A2%2525C8%252599tig%2525C4%252583torul-games-science%3FtrackingId=1cmtxzsUo6Ga3qTnGf4QbA%253D%253D/?trackingId=1cmtxzsUo6Ga3qTnGf4QbA%3D%3D" target="_blank"><img src={gosLOGO} className="logo"/></Link>
              <Link className="brands" to="https://lexonn.ro" target="_blank"><img src={lexonnLOGO} className="logo"/></Link>
              <Link className="brands" to="https://vonfediuc.com" target="_blank"><img src={vonfLOGO} className="logo"/></Link>
            </motion.div>
          </div>
        </div>
      </motion.div>
      
      <Footer/>
    </>
  );
}

export default Help;
