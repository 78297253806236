import React, { useState, useEffect, useNavigate } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import Navigation from '../components/Navigation'
import Chat from '../components/Chat-Interface'

import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'


function ChatPage() {
  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/messenger" });
  }, [])

  return (
    <>
      <SEO
        title="Psiholog Cool - E cool la psiholog!"
        description="Psiholog cool pentru toata lumea."
        type="webapp"
        name="Psiholog Cool"
      />
      
      <Chat/>
    </>
  );
}

export default ChatPage;
