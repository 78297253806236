import React, { useState, useEffect, useRef } from 'react';
import { PopupButton } from 'react-calendly';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { AnimatePresence, motion } from 'framer-motion';
import { chatInterfaceAnimation, userInputAnimation, modalAnimation, quickFadeAnimation } from '../components/Animations';

import Bubble from './Bubble';
import SplashScreen from './Splash';

import Navigation from '../components/Navigation';
import Programare from '../components/Programare';

const ChatInterface = () => {
  const [chatBubbles, setChatBubbles] = useState([
    // Initial chat bubbles
    { text: "Salut! 👋 Mă bucur că ai făcut acest prim pas, accesând Psiholog Cool, o soluție când ai nevoie de ajutor, dar nu știi de unde să începi sau ce ai încercat până acum nu a funcționat. Aici poți să îmi lași un mesaj și apoi te poți programa pentru a discuta direct cu mine, un psiholog real.", delay: 2500 },
    { text: "Sunt Iulia Scărlătescu, psiholog și psihoterapeut atestat. Spune-mi ce te preocupă sau care este situația căreia îi cauți o soluție. 🙂", delay: 4000 },
  ]);

  const [userMessage, setUserMessage] = useState('');
  const [userMessageSent, setUserMessageSent] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showCounter, setShowCounter] = useState(true);
  const [userInputMessage, setUserInputMessage] = useState(true);
  const [showReplyButtons, setShowReplyButtons] = useState(false);
  const [showCalendlyButton, setShowCalendlyButton] = useState(false);
  const [userInputEmail, setUserInputEmail] = useState(false);

  const [showHugReaction, setShowHugReaction] = useState(false);
  const [showHeartReaction, setShowHeartReaction] = useState(false);

  const chatContainerRef = useRef(null);

  const handleUserReply = (buttonValue) => {
    if (!showReplyButtons) {
      // Logic to handle user reply and add new chat bubbles
      const userBubble = { text: userMessage, delay: 0, isUserInput: true };
      const replyBubbles = [
        userBubble,
        { text: "Înțeleg. Ai luat o decizie corectă, căutând ajutor de specialitate. Te invit să începem cu o sesiune online de 15 minute.", delay: 2500 },
        { text: "Ce spui? Facem programarea pentru sesiunea online de 15 minute? 🙂", delay: 3300 },
      ];
      setShowHugReaction(true);
      // Add the reply bubbles after the delay
      replyBubbles.forEach((bubble, index) => {
        setTimeout(() => {
          setChatBubbles((prevBubbles) => [...prevBubbles, bubble]);
        }, bubble.delay);
      });

      // Scroll to the bottom after a delay to allow the bubbles to render
      setTimeout(() => {
        scrollToBottom();
        setTimeout(() => {
          scrollToBottom();
        }, 1500);
      }, 5200);

      setUserMessage('');
      setUserInputMessage(false);

      setTimeout(() => {
        setShowReplyButtons(true);
        setUserMessageSent(userMessage);
      }, 8000);
    } else {
      // Handle button click based on the user input
      if (buttonValue === "Nu știu sigur...") {
        functionToPostNewBubbleAndReplyInput();
      } else if (buttonValue === "Da, vreau.") {
        functionToPostNewBubbleAndReplyCalendly();
        setShowHeartReaction(true);
      }
    }
  };

  const functionToPostNewBubbleAndReplyInput = () => {
    // Handle the logic for posting a new user input bubble and reply bubbles based on the first button click
    const userBubble = { text: "Nu știu sigur...", delay: 0, isUserInput: true };
    const replyBubbles = [
      userBubble,
      { text: "În regulă. Când te simți pregătit te aștept să revii. Dacă vrei, poți să-mi lași mail-ul și îți trimit eu un reminder în câteva zile.", delay: 1000 },
    ];
    setShowHugReaction(false);
    // Add the reply bubbles after the delay
    replyBubbles.forEach((bubble, index) => {
      setTimeout(() => {
        setChatBubbles((prevBubbles) => [...prevBubbles, bubble]);
      }, bubble.delay);
    });

    setUserMessage('');
    setShowReplyButtons(false);

    // Scroll to the bottom after a delay to allow the bubbles to render
    setTimeout(() => {
      scrollToBottom();
    }, 2200);

    setTimeout(() => {
      setUserInputEmail(true);
    }, 3000);
  };

  const functionToPostNewBubbleAndReplyCalendly = () => {
    // Handle the logic for posting a new user input bubble and reply bubbles based on the second button click
    const userBubble = { text: "Da, vreau.", delay: 0, isUserInput: true };
    const replyBubbles = [
      userBubble,
      { text: "Cool! 😎 Te rog să selectezi data și ora dorită. Pe curând! 👋", delay: 1000 },
      // Additional chat bubbles in response to the second button click
    ];

    // Add the reply bubbles after the delay
    replyBubbles.forEach((bubble, index) => {
      setTimeout(() => {
        setChatBubbles((prevBubbles) => [...prevBubbles, bubble]);
      }, bubble.delay);
    });

    setUserMessage('');
    setShowReplyButtons(false);

    // Scroll to the bottom after a delay to allow the bubbles to render
    setTimeout(() => {
      scrollToBottom();
    }, 2200);

    setTimeout(() => {
      setShowCalendlyButton(true);
    }, 3000);
  };

  const functionToTest = () => {
    if (!validateEmail(userEmail)) {
      alert('Introdu o adresă de email validă!');
      return;
    }

    setShowHeartReaction(true);
    // Handle the logic for posting a new user input bubble and reply bubbles based on the second button click
    const userBubble = { text: userEmail, delay: 0, isUserInput: true };
    const replyBubbles = [
      userBubble,
      { text: "Am notat, mersi! Am să-ți trimit un reminder în câteva zile. Pe curând! 👋", delay: 1000 },
      // Additional chat bubbles in response to the second button click
    ];

    // Add the reply bubbles after the delay
    replyBubbles.forEach((bubble, index) => {
      setTimeout(() => {
        setChatBubbles((prevBubbles) => [...prevBubbles, bubble]);
      }, bubble.delay);
    });

    setUserMessage('');
    setUserEmail('');
    setShowReplyButtons(false);
    setUserInputEmail(false);

    // Scroll to the bottom after a delay to allow the bubbles to render
    setTimeout(() => {
      scrollToBottom();
    }, 2200);

    // Send email to Herotofu
    const formData = new FormData();
    formData.append('Email', userEmail);

    fetch('https://public.herotofu.com/v1/43d76930-1f41-11ee-8267-d3eb100789b4', {
      method: 'POST',
      body: formData,
    }).then(() => {
      console.log('Email sent successfully!');
    }).catch((error) => {
      console.log('Error sending email:', error);
    });
  };

  const [seeTheChat, setSeeTheChat] = useState(false);
  const [seeSplash, setSeeSplash] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSeeSplash(false);
      setSeeTheChat(true);
    }, 3500);

    return () => clearTimeout(timeout);
  }, []);

  const messagesEndRef = useRef();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatBubbles]);

  const [seeCalendly, setSeeCalendly] = useState(false);

  const showCalendlyFunction = () => {
    setSeeCalendly(true);
  };

  const hideCalendlyFunction = () => {
    setSeeCalendly(false);
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      {seeSplash && <SplashScreen />}

      {seeCalendly && (
        <>
          <motion.div
            className="modal modalAnimation"
            key="modal"
            variants={modalAnimation}
            animate="show"
            exit="hide"
          >
            <MobileView>
              <button className="button show-hide-chat-btn hide" onClick={hideCalendlyFunction}></button>
            </MobileView>
            <Programare userReply={userMessageSent} />
          </motion.div>

          <motion.div
            className="modal-overlay quickFadeAnimation"
            key="overaly"
            variants={quickFadeAnimation}
            animate="show"
            exit="hide"
          />
        </>
      )}

      {seeTheChat && (
        <motion.div
          key="chat"
          className="chatInterfaceAnimation"
          variants={chatInterfaceAnimation}
          animate="show"
          exit="hide"
        >
          <Navigation />

          <div className="chat">
            <div className="chat-container" ref={chatContainerRef}>
              {chatBubbles.map((bubble, index) => {
                const { text, delay, isUserInput } = bubble;
                const isLastBubble = index === chatBubbles.length - 1;

                return (
                  <React.Fragment key={index}>
                    <Bubble
                      id={`bubble-${index}`}
                      text={text}
                      delay={delay}
                      isLastBubble={isLastBubble}
                      isUserInput={isUserInput}
                      shouldDisplayCounter={!isLastBubble && showCounter}
                      hugReaction={showHugReaction}
                      heartReaction={showHeartReaction}
                    />
                  </React.Fragment>
                );
              })}
              <div ref={messagesEndRef} />
            </div>

            <div className="reply-container">
              <div className="reply-cta">
                {userInputMessage && (
                  <motion.div
                    key="user-input"
                    className="reply-input userInputAnimation"
                    variants={userInputAnimation}
                    animate="show"
                    exit="hide"
                  >
                    <input
                      type="text"
                      className="reply"
                      placeholder="Introdu mesajul tău…"
                      value={userMessage}
                      onChange={(e) => setUserMessage(e.target.value)}
                    />
                    <button className="button send-btn" onClick={handleUserReply} disabled={!userMessage} />
                  </motion.div>
                )}

                {showReplyButtons && (
                  <motion.div
                    key="user-input2"
                    className="reply-choice userInputAnimation"
                    variants={userInputAnimation}
                    animate="show"
                    exit="hide"
                  >
                    <button className="button choice secondary" onClick={() => handleUserReply("Nu știu sigur...")}>
                      Nu știu sigur...
                    </button>
                    <button className="button choice primary" onClick={() => handleUserReply("Da, vreau.")}>
                      Da, vreau.
                    </button>
                  </motion.div>
                )}

                {showCalendlyButton && (
                  <motion.div
                    key="user-input3"
                    className="reply-choice userInputAnimation"
                    variants={userInputAnimation}
                    animate="show"
                    exit="hide"
                  >
                    <button className="button choice primary" onClick={showCalendlyFunction}>
                      Selectează data și ora
                    </button>
                  </motion.div>
                )}

                {userInputEmail && (
                  <motion.div
                    key="user-input4"
                    className="reply-input userInputAnimation"
                    variants={userInputAnimation}
                    animate="show"
                    exit="hide"
                  >
                    <form>
                      <input
                        name="Email"
                        id="email"
                        type="email"
                        className="reply"
                        placeholder="Introdu email-ul tău…"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                      />

                      <input
                        className="send-btn"
                        type="submit"
                        value=""
                        onClick={(e) => {
                          e.preventDefault();
                          functionToTest();
                        }}
                        disabled={!userEmail}
                      />
                    </form>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default ChatInterface;
