import React, { useState, useEffect, useNavigate } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion, useViewportScroll, useTransform } from 'framer-motion'
import { contentAnimation, circleAnimation } from '../components/Animations'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'


import Navigation from '../components/Navigation'
import StaticNavigation from '../components/StaticNavigation'
import Footer from '../components/Footer'

import mp4_1 from '../assets/video/5.mp4'
import mp4_2 from '../assets/video/9.mp4'
import mp4_3 from '../assets/video/6.mp4'
import mp4_4 from '../assets/video/8.mp4'

function Preturi() {
  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/onorariu" });
  }, [])


  const { scrollYProgress } = useViewportScroll();

  const zoomAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.3], [1, 2]),
    translateY: useTransform(scrollYProgress, [0, 0.3], [1, 300])
  };

  const testAnimation = {
    scale: useTransform(scrollYProgress, [0, 0.3], [1, 0.95]),
    translateY: useTransform(scrollYProgress, [0, 0.3], [1, -200])
  };

  

  const [showModal, setShowModal] = useState(false)
  
  function showCalendlyFunction(){
    setShowModal(true)
  }
  const hideModal = (hideModal) => {
    console.log(hideModal);
    setShowModal(hideModal)
  }

  return (
    <>
      <SEO
        title="Psiholog Cool - Onorariu"
        description="Prima sesiune introductivă de psihoterapie GRATUIT!"
        type="webapp"
        name="Psiholog Cool"
      />
        
      <MobileView>
        <Navigation showModal={showModal} hideModal={hideModal}/>
      </MobileView>
      
      <BrowserView>
        <StaticNavigation showModal={showModal} hideModal={hideModal}/>
      </BrowserView>

      <motion.div
        key="page"
        className="page onorariu contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      > 
        <motion.div className="color" style={testAnimation}>
          <div className="content wide">
            <div className="grid">
              <div className="col">
                <div>
                  <h1 className="page-title">Prima sesiune introductivă GRATUIT!</h1>
                  <br/>
                  {/*<h3>GRATUIT - Prima sesiune introductivă.</h3>*/}
                  <p>Exclusiv online (15 minute).</p>
                  <p>
                    <button className="button big-btn" onClick={showCalendlyFunction}>Alege și programează-te!</button>
                  </p>
                </div>
              </div>

              <div className="col top">
                <div className="hidden-mobile">
                  <motion.div 
                    className="circle-mask onorariu circleAnimation"
                    key="circle0"
                    variants={ circleAnimation } 
                    animate='show' 
                    exit='hide'                
                  >
                    <motion.video className="landing-video" style={zoomAnimation} src={mp4_1} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
                  </motion.div>
                </div>
              </div>

            </div>
          </div>
        </motion.div>

          <br/>
          <br/>

        <div className="content wide">
          
          {/*<hr/>*/}
          <div className="grid">

            <div className="col">
              <div>
                <h3>Onorariu ședință standard</h3>
                <p>Online sau față în față la cabinet.</p>
                <p><strong>250 RON</strong> (50 minute)</p>
                <p>
                  <button className="button small-btn" onClick={showCalendlyFunction}>Alege și programează-te!</button>
                </p>
               </div>
            </div>

            <div className="col">
              <div>
                <h3>Ai nevoie de ceva special?</h3>
                <p>În cazul în care ai o cerință sau nevoie specială.</p>
                <p>Te invit călduros să vorbim!</p>
                <p><Link to="/contact"><button className="button small-btn">Contact</button></Link></p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <Footer/>
    </>
  );
}

export default Preturi;
