import React, { useState } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import './styles/main.scss'

import { AnimatePresence, motion } from 'framer-motion'

import ReactGA from "react-ga4";

import Landing from './pages/Landing'
import Help from './pages/Help'
import Despre from './pages/Despre'
import Onorariu from './pages/Onorariu'
import Messenger from './pages/ChatPage'
// import Programare from './pages/ProgramarePage'
import FAQ from './pages/FAQ'
import Contact from './pages/Contact'
import Legal from './pages/Legal'
import PageNotFound from './pages/404'

import Test from './components/Chat-Interface'

function App() {
  ReactGA.initialize("G-WFGG06GG61");


  const location = useLocation();
  return (
    <div className="App">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}> 
          <Route exact path="/" element={<Landing/>}/> 
          <Route exact path="/help" element={<Help/>} />
          <Route exact path="/despre" element={<Despre/>} />
          {/*<Route exact path="/programare" element={<Programare/>} />*/}
          <Route exact path="/intrebari-frecvente" element={<FAQ/>} />
          <Route exact path="/onorariu" element={<Onorariu/>} />
          <Route exact path="/messenger" element={<Messenger/>} />
          <Route exact path="/contact" element={<Contact/>} />
          <Route exact path="/legal" element={<Legal/>} />

          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
