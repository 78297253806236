import React, { useState, useEffect, useNavigate } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ReactGA from "react-ga4";
import { SEO } from '../components/SEO'


import Navigation from '../components/Navigation'
import StaticNavigation from '../components/StaticNavigation'

import Footer from '../components/Footer'

function Contact() {
  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/contact" });
  }, [])

  return (
    <>
      <SEO
        title="Psiholog Cool - Contact"
        description="Hai să vorbim!"
        type="webapp"
        name="Psiholog Cool"
      />
      
      <MobileView>
        <Navigation/>
      </MobileView>
      
      <BrowserView>
        <StaticNavigation/>
      </BrowserView>

      <motion.div
        key="page"
        className="page contact contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      > 
        <div className="content wide">
          <h1 className="page-title">Contact</h1>
          <br/>
          {/*<p>Ne putem vedea față în față, la cabinetul meu sau online.</p>*/}
          <p>Dacă ai orice întrebare, te invit călduros să îmi scrii.</p>
          <br/>
        
          <div className="grid">
            <div className="col">
              <div>
                <br/>
                <form action="https://public.herotofu.com/v1/d3f4bde0-1f2f-11ee-8025-97a9fb2f29da" method="post">
                  <input className="input-field" name="Nume" id="name" type="text" placeholder="Numele tău" required />
                  <input className="input-field" name="Email" id="email" type="email" placeholder="Email-ul tău" required  />
                  <textarea className="input-field" name="Mesaj" id="message" type="text" placeholder="Mesajul tău..." required  />
                  <br/>
                  <br/>
                  <input className="submit-btn" type="submit" value="Trimite mesaj"/>
                </form>
              </div>
            </div>

            <div className="col top">
              <div>
                <h3>Program</h3>
                <p className="contact">Luni - Vineri, 08:00 - 20:00</p>
              </div>
            </div>
            
          </div>
          <br/>
        </div>
      </motion.div>
      <Footer/>
    </>
  );
}

export default Contact;
